export const validUser = new RegExp('[A-Z0-9]{10}')
export const validPassword = new RegExp('^.{12,25}$')

export const validNewPassword = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!"#$%&\'()*+,-./:;<=>?@[\\\\\\]^_`{|}~]).*$'
)

export const atleastOneUppercase = new RegExp('[A-Z]')
export const atleastOneLowercase = new RegExp('[a-z]')
export const atleastOneNumber = new RegExp('[0-9]')
export const atleast12Characters = new RegExp('^.{12,}$')
export const max25Characters = new RegExp('^.{0,25}$')
export const noExtraSpaces = new RegExp('/s')
export const specialCharacter = new RegExp('[!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]')
