import styled from 'styled-components'
import { validPassword } from 'utils/regex.jsx'
import { useEffect, useState } from 'react'
import Auth from 'api/Auth'
// import config from 'config'
import AppStore from 'utils/AppStore'
import { showError } from 'utils'
import i18n, { changeLanguage, T, __ } from 'translations/i18n'
import { Button, Input, Spacer } from 'components'
import ApiError, { User } from 'types'
import { ReactComponent as LoadingIcons } from 'assets/loading.svg'
import Cookies from 'universal-cookie'

export default function LoginCard({
  onClickPasswordForgot,
  onClickEditPassword,
  onClickContactUs,
  onClickMainButtonWithMultipleERPUsers,
  onLoginCallback,
  onPasswordExpired,
  onPasswordScaduta,
  usercode,
}: {
  onClickPasswordForgot: (value: boolean) => void
  onClickEditPassword: (value: boolean) => void
  onClickContactUs: (value: boolean) => void
  onClickMainButtonWithMultipleERPUsers: () => void
  onLoginCallback: React.Dispatch<React.SetStateAction<User>>
  onPasswordExpired: (value: boolean) => void
  onPasswordScaduta: (string) => void
  usercode: string
}) {
  const [username, setUsername] = useState('') // 9JGSLR87BI E7L98WFSX1  ADMIN12345
  const [password, setPassword] = useState('') //Password1234!  qQuCwN%F48btquo   QxRwn82I%jDudtl     Admin_1_2_3_4_5
  const [loading, setLoading] = useState(false)
  const cookie = new Cookies()

  useEffect(() => {
    setUsername(usercode)
  }, [usercode])

  const onLogin = async () => {
    if (!username) return showError(__(T.error.user_not_valid))
    if (!validPassword.test(password)) return showError(__(T.error.password_not_valid))

    try {
      setLoading(true)
      const res = await Auth.login(username, password)
      await AppStore.setToken(res.token)
      AppStore.loggedUser = await Auth.loggedUser()
      setLoading(false)

      //setto di default il valore a 1: Dasboard, index indica in quale categoria sono
      localStorage.setItem('index', '1')

      if (AppStore.loggedUser.erpUsers.length === 1) {
        AppStore.loggedErpUser = AppStore.loggedUser.erpUsers[0].code
        cookie.set('loggedBusinessName', AppStore.loggedUser.erpUsers[0].name)
        cookie.set('loggedErpUser', AppStore.loggedErpUser, { path: '/' })
        changeLanguage(AppStore.getLanguageValue())
        cookie.set('language', AppStore.getLanguageValue())
        onLoginCallback(AppStore.loggedUser)
      } else if (AppStore.loggedUser.erpUsers.length === 0) {
        AppStore.loggedErpUser = 'NO ERP CODE'
        cookie.set('loggedErpUser', 'NO ERP CODE', { path: '/' })
        changeLanguage(AppStore.getLanguageValue())
        cookie.set('language', AppStore.getLanguageValue())
        onLoginCallback(AppStore.loggedUser)
      } else {
        onClickMainButtonWithMultipleERPUsers()
      }
    } catch (error: any) {
      setLoading(false)
      const knownError = error.error as ApiError

      switch (knownError.code) {
        case 'WRONG_CREDENTIALS':
          showError(i18n.t('login_page.login_card.wrong_credentials'))
          break
        case 'EXPIRED_PASSWORD':
          showError(i18n.t('login_page.login_card.expired_password'))
          onPasswordExpired(true)
          onPasswordScaduta(username)
          break
        case 'USER_NOT_FOUND':
          showError(i18n.t('login_page.login_card.user_not_found'))
          break
      }
    }
  }

  return (
    <>
      {!loading && (
        <>
          <Title>{i18n.t('login_page.login_card.title')}</Title>
          <FormContainer>
            <Input
              type="text"
              title={i18n.t('login_page.login_card.input_username.label')}
              placeholder={i18n.t('login_page.login_card.input_username.value')}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Spacer />
            <Input
              type="password"
              title={i18n.t('login_page.login_card.input_password.label')}
              placeholder={i18n.t('login_page.login_card.input_password.value')}
              defaultValue={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormContainer>

          <TextEditPassword onClick={onClickEditPassword}>
            {i18n.t('login_page.login_card.edit_password')}
          </TextEditPassword>

          <TextForgotPassword onClick={onClickPasswordForgot}>
            {i18n.t('login_page.login_card.contact_support')}
          </TextForgotPassword>
          <CustomButton onClick={onLogin} text={i18n.t('login_page.login_card.button')} />
        </>
      )}
      {loading && <LoadingIcons />}
    </>
  )
}

const FormContainer = styled.div`
  width: 25vw;
`

const Title = styled.h1`
  place-content: center;
  margin-top: 1vw;
  margin-bottom: 1vw;
  font-size: 4vw;
  font-family: cormorant-garamond, serif;
  font-weight: 500;
  font-style: normal;

  font-family: cormorant-garamond, serif;
  font-weight: 500;
  font-style: normal;
  /* or 44% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Neutral/Black */

  color: #28293d;
`

const TextForgotPassword = styled.span`
  place-content: center;
  left: 520px;
  top: calc(50% - 24px / 2 + 78px);

  margin-top: 2px;
  margin-bottom: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;

  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #28293d;

  /* color: rgba(0, 0, 0, 0.25); */
  cursor: pointer;
`

const TextEditPassword = styled.span`
  place-content: center;
  left: 520px;
  top: calc(50% - 24px / 2 + 78px);

  margin-top: 16px;
  margin-bottom: 2px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;

  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #28293d;

  /* color: rgba(0, 0, 0, 0.25); */
  cursor: pointer;
`

const CustomButton = styled(Button)`
  font-size: 1.3em !important;
  padding: 12 - 180px !important;
`
