/* eslint-disable import/no-anonymous-default-export */
export default {
  login_page: {
    login_card: {
      title: 'Welcome',
      input_username: {
        label: 'User Code',
        value: 'Insert here your user code',
      },
      input_password: {
        label: 'Password',
        value: 'Insert here your password',
      },
      forgotten_password: 'Forgot your password? Request a new one',
      contact_support: 'Need support? Contact us by clicking here',
      edit_password: 'Edit password',
      button: 'Proceed',
      wrong_credentials: 'Wrong credentials',
      expired_password: 'Expired Password',
      user_not_found: 'User not found',
    },
    edit_password: {
      title: 'Edit Password',
      input_username: {
        label: 'User Code',
        value: 'Enter User Code',
      },
      input_new_password: {
        label: 'New Password',
        value: 'Enter new password',
      },
      input_confirm_new_password: {
        label: 'Confirm New Password',
        value: 'Enter new password',
      },
      input_old_password: {
        label: 'Old Password',
        value: 'Insert Old Password',
      },
      button: 'Confirm',
      wrong_credentials: 'Wrong old password',
      correct_credentials: 'Passwod Updated Successfully',
      user_not_found: 'User not found',
      required_fields: 'Required Fields',

      empty_input_fields: 'Fill in all required fields',

      new_password_error:
        'New Password must contain at least an uppercase character, a lowercase character, a number, a special character, and no spaces are allowed.',

      confirm_password_error:
        'Confirm Password must contain at least an uppercase character, a lowercase character, a number, a special character, and no spaces are allowed.',

      empty_new_password: 'Please insert new password',
      password_length_error: 'The password must be at least 12 characters long and less than 25 characters',

      new_password_diff_confirm_password: 'New Password and Confirm New Password must be the same',

      too_short: 'Password must be at least 12 chars long',
      too_long: 'Password must be at most 25 chars long',
      missing_numbers: 'Password must contain at least one digit',
      missing_special_char: 'Password must contain at least one special char',
      missing_uppercase_char: 'Password must contain at least one upper case char',
      missing_lowercase_char: 'Password must contain at least one lower case char',
      spaces_not_allowed: 'No spaces allowed',
      new_password_equal_to_old: 'The new password must be different from the old one',
      default_error: 'Error, try again'
    },
    forgotten_password: {
      title: 'Require password',
      success: 'Password requested successfully!',
      success_send_mail: 'Request for assistance successfully sent!',
      input_name: {
        label: 'Name',
        value: 'Your name',
      },
      input_surname: {
        label: 'Surname',
        value: 'Your surname',
      },
      input_company: {
        label: 'Company name',
        value: 'Company you work for',
      },
      input_email: {
        label: 'Email',
        value: 'Your e-mail',
      },
      button: 'Require password',

      error_user_not_found: 'User not found',
      error_email_format: 'Incorrect email format',
      error_form_not_complete: 'Fill in all fields',
    },
    contact_us: {
      title: 'Contact us',
      success: 'Password requested successfully!',
      input_name: {
        label: 'Name',
        value: 'Your name',
      },
      input_surname: {
        label: 'Surname',
        value: 'Your surname',
      },
      input_company: {
        label: 'Company name',
        value: 'Company you work for',
      },
      input_email: {
        label: 'Email',
        value: 'Your e-mail',
      },
      input_type_support: {
        label: 'Type of assistance required',
        value: 'The subject of your email',
      },
      subject_options: {
        forgot_password: 'Request new password',
        new_user: 'New user registration',
        other: 'Other',
      },
      input_message: {
        label: 'Message',
        value: 'Your message',
      },
      button: 'Send',

      error_fill_form: 'Fill in all the fields',
      error_email_format: 'Incorrect email format',
      error_user_not_found: 'Cannot find user among registered users',
      error_support_mail: 'An error occurred! Cannot send email',
      error_no_subject_or_msg: 'No subject or email inserted',
    },
    erp_user: {
      title: 'Welcome',
      select_erp_user: {
        label: 'Utilities ERP',
        value: 'Code',
      },
      button: 'Enter',

      error_no_selection: 'Choose an ERP user to proceed',
    },
  },

  sidebar: {
    title: 'Hello',
    menu: {
      home: 'Home',
      dashboard: 'Dashboard',
      availability: 'Web availability',
      cart: 'My orders',
      order_history: 'My previous orders',
      documentary: 'Documentary',
      logistic_documents: 'Logistic documents',
      logout: 'Logout',
      admin: 'Admin functionality',
    },
  },
  dashboard_page: {
    title: 'Your dashboard',
    subtitle: 'Here you can view the progress of your orders',
    select_filter_by: {
      placeholder: 'Filter by',
      option_amount: 'Amount',
      option_quantity: 'Quantity',
    },
    select_filter_by_code: {
      placeholder: 'Filter by code',
    },
    select_filter_by_purchase: {
      placeholder: 'Filter by purchase',
    },
  },

  orderHistoryPage: {
    page_title: 'Your previous orders',
    error_retrieve: 'An error occured! Cannot retrieve your previous orders',
    select: {
      search: 'Search',
      order_by: {
        placeholder: 'Order for',
        asc: 'Ascending date',
        desc: 'Descending date',
      },
    },
    header_columns: {
      order: 'Order',
      delivery_date: 'Order date',
      status: 'Order status',
    },
    orderStatus: {
      inserito: 'Entered',
      spedito_parziale: 'Partially shipped',
      approvato: 'Approved',
      spedito: 'Shipped',
      in_preparazione: 'In process',
    },
    deliveryDateStatus: {
      estimated_date: 'Estimated date',
      not_available: 'not available',
    },
    drawer: {
      title: 'Order n.',
      product_column: 'Product',
      delivery_date_column: 'Delivery date',
      meters_column: 'Meters',
      total_meters: 'Total meters',
      express_shipment: 'Express delivery',
      standard_shipment: 'Standard delivery',
      externalDocumentNo: 'External document no.',
    },
  },

  availabilityPage: {
    main: {
      title_1: 'Your recent searches',
      input_placeholder: 'Search for articles',
      left_text_button: 'search',
      title_2: 'Results for: ',
      delete_message: 'Do you want to delete this search?',

      error_delete_recent_search: 'An error has occured! Cannot delete search line',
      error_item_availability: 'An error occurred! Cannot retrieve item availability',
    },
    drawer: {
      title: 'Fabric ',
      title_1: 'Download commercial sheet',
      title_2: 'Available pieces',
      label_short_lots: 'Available pieces with limited length',
      button_2: 'View available pieces',
      button_2_1: 'No pieces available',

      subtitle_2: '',
      title_3: 'In arrival',
      button_3: 'View future availability',
      button_3_1: 'No future availability found',
      left_button: 'Meters',
      right_button: 'Availability',
      button: 'Continue',
      label_batches: 'Selected batches',
      label_meters: 'Meters',
      label_discount: '!! 30% discount !!',

      error_msg: 'No lot selected or meters entered',

      //modal_content: 'The availability does not cover the desired order.',
    },
    sub_drawer_1: {
      title: 'Total available pieces: ',
      subtitle_1: 'Batch n.',
      subtitle_2: 'Piece n.',
      subtitle_3: 'Meters',
      column_support: 'Full height',
      button: 'Continue',
      qty_selectable: 'Selectable quantity: ',
      qty_selected: 'Selected quantity: ',

      modal_title: 'Attention',
      modal_content: 'Maximum selectable quantity exceeded. The last chosen batch will be unchecked.',
    },
    sub_drawer_2: {
      title: 'Availability in arrival',
      subtitle_1: 'Date',
      subtitle_2: 'Total availability',
    },
    sub_drawer_3: {
      title_1: 'Selected batches',
      title_short_lots: 'Batches with limited length',
      title_2: 'Bulk meters',
      label_prospect_dates: 'Date',
      label_delivery: 'Delivery date',
      label_status: 'Status',
      status_immediateAvailability: 'immediate availability',
      status_futureAvailability: 'future availability',
      status_remainings: 'unavailable',
      label_total: 'Total',
      //tooltip_delivery_info: 'La data di consegna potrebbe subire variazioni',
      tooltip_delivery_info:
        'Depending on the return time of the item in stock, the delivery date may be subject to change.',
      button_add_to_cart: 'Add to cart ',

      support_disclaimer: 'batch available in a different support than the preferred one',
      error_cart: 'An error occured! Cannot create a cart',
      info_add_to_cart: 'Items correctly added to cart',
      error_add_to_cart: 'An error occurred! Cannot add items to cart',
      error_nothing_to_add: 'No meter or lot to add to cart',
      modal_content:
        'Some of the selected lots are available in a different support than the preferred one. Would you still like to add to cart?',
      modal_cancel_button: 'Cancel',
    },
  },
  cartPage: {
    title: 'Your orders',
    subtitle1: 'Product',
    subtitle2: 'Pieces n./Q.ty',
    subtitle3: 'Delivery date',
    cart_row: ' pieces | Tot.  ',
    cart_delete: 'Do you want to delete the product?',
    cart_delete_response: 'Deleted product',
    cart_empty: 'Empty cart',
    info_confirm: 'Thank you for your order!',
    error_confirm: 'An error occured! Cannot send the order',
    error_delete_row: 'An error occured! Could not delete command line',
    error_email_format: 'Invalid mail format',

    modal_title: 'Thank you for your purchase!',
    modal_content: 'Your order has been successfully processed',

    drawer: {
      title1: 'Total meters',
      title2: 'Number of pieces',
      title3: 'Meters with piece',
      title4: 'Remaining meters',
      button: 'Confirm',
      express_shipment: 'Shipping via express courier',
      external_document: 'External document no.',
    },
  },

  documentalPage: {
    title: 'Documentary',
    category: {
      category1: 'Document N.',
      category2: 'Document Date',
      category3: 'Download',
    },
    input_docType: {
      order: 'Orders',
      ddt: 'DDT',
      packinglist: 'Packing list',
      invoice: 'Invoices',
    },
  },

  customersPage: {
    searchbar_placeholder: 'Search',
    select_placeholder: 'Order by',
    checkbox_active: 'Active',
    checkbox_inactive: 'Inactive',
    button_add_user: 'Add user',

    option_expired_password: 'PASSWORD EXPIRATION',
    option_company: 'COMPANY NAME',

    button_extended: 'Extended',
    button_limited: 'Limited',
    button_expiration_days: '30+ days',
    button_days: 'days',

    drawer_password: {
      title: 'Password will expire in: ',
      subtitle_days: 'days',
      button_generate: 'Generate new password',
      button_confirm: 'Confirm',

      error_password: 'An error occured! Cannot generate a new password',
      info_modify_password: 'Password successfully modified',
      error_modify_password: 'An error occured! Password not modified',
    },

    drawer_language: {
      title1: 'Language in use: ',
      italian: 'ITALIAN',
      english: 'ENGLISH',
      spanish: 'SPANISH',
      title2: 'Assign a language: ',
      button_confirm: 'Confirm',

      info_changed_language: 'Language successfully modified to: ',
      error_add_user: 'An error occured! Cannot insert new user',
    },

    drawer_info: {
      title1: 'Information about profile with ID: ',
      label_name: 'Name',
      label_surname: 'Surname',
      label_email: 'Email',
      label_business_name: 'Company name',
      title2: 'Profile type',
      label_erp_users: 'ERP users',
      button_confirm: 'Confirm',
      placeholder_erp_users: 'Search here your erp users',

      info_modify_user: 'User successfully modified',
      error_modify_user: "An error occured! Cannot save modified user's informations",
      error_erp_users: 'An error occured! Cannot load ERP users',

      modal_title: 'Attention! ',
      modal_context: "You are modifying user's role",
      modal_okText: 'I understand',
      modal_okType: 'danger',
    },

    customer_row: {
      info_active: "User's Active state successfully modified",
      error_active: 'An error occured! Cannot modify user Active state',
      info_limited: 'User catalogue visibility successfully modified',
      error_limited: 'An error occured! Cannot modify user catalogue visibility',
    },
  },

  domain: {
    select_order_by: {
      date: {
        asc: 'Ascending date',
        desc: 'Descending date',
      },
    },
  },

  homePage: {
    greeting: 'Welcome to',
  },

  misc: {
    erps_selected: 'Users selected',
  },
  error: {
    user_not_valid: 'User not valid',
    password_not_valid: 'Password not valid',
    user_or_password_wrong: 'User or password not valid',
  },
  warning: {
    warning_cart_two_minutes: 'The cart will be emptied in less than 2 minutes!',
    warning_cart_ten_seconds: 'The cart will be emptied in 10 seconds!',
  },
  messages: {},
  placeholder: {},
  confirm: {},
  labels: {},
}
