/* eslint-disable import/no-anonymous-default-export */
export default {
  login_page: {
    login_card: {
      title: 'Benvenuto',
      input_username: {
        label: 'Codice Utente',
        value: 'Inserisci qui il tuo codice utente',
      },
      input_password: {
        label: 'Password',
        value: 'Inserisci qui la tua password',
      },
      forgotten_password: 'Password dimenticata? Richiedine una nuova',
      contact_support: 'Hai bisogno di assistenza? Contattaci cliccando qui',
      edit_password: 'Modifica password',
      button: 'Procedi',
      wrong_credentials: 'Credenziali sbagliate',
      expired_password: 'Password scaduta',
      user_not_found: 'Utente non trovato',
    },
    edit_password: {
      title: 'Modifica password',
      input_username: {
        label: 'Codice Utente',
        value: 'Inserire Codice Utente',
      },
      input_new_password: {
        label: 'Nuova password',
        value: 'Inserire nuova password',
      },
      input_confirm_new_password: {
        label: 'Conferma Nuova Password',
        value: 'Inserisci nuova password',
      },
      input_old_password: {
        label: 'Vecchia password',
        value: 'Inserire vecchia password',
      },
      button: 'Conferma',
      wrong_credentials: 'Vecchia password sbagliata',
      correct_credentials: 'Password aggiornata con successo',
      user_not_found: 'Utente non trovato',
      required_fields: 'Campi Obbligatori',

      empty_input_fields: 'Compilare tutti i campi obbligatori',

      new_password_error:
        'La nuova password deve avere un carattere maiuscolo, uno minuscolo, un numero, un carattere speciale e non sono ammessi spazi.',

      confirm_password_error:
        'Conferma password deve avere un carattere maiuscolo, uno minuscolo, un numero, un carattere speciale e non sono ammessi spazi.',

      empty_new_password: 'Per favore inserire la nuova password',

      password_length_error: 'La password deve essere di almeno 12 caratteri ed inferiore a 25 caratteri',

      new_password_diff_confirm_password: 'Nuova password e Conferma nuova password devono coincidere',

      too_short: 'La password deve essere di almeno 12 caratteri',
      too_long: 'La password deve essere di massimo 25 caratteri',
      missing_numbers: 'La password deve contenere almeno un numero',
      missing_special_char: 'La password deve contenere almeno un carattere speciale',
      missing_uppercase_char: 'La password deve contenere almeno un carattere maiuscolo',
      missing_lowercase_char: 'La password deve contenere almeno un carattere minuscolo',
      spaces_not_allowed: 'Non sono ammessi spazi',
      new_password_equal_to_old: 'La nuova password deve essere diversa dalla vecchia',
      default_error: 'Errore, riprovare'
    },
    forgotten_password: {
      title: 'Richiedi password',
      success: 'Password richiesta con successo!',
      input_name: {
        label: 'Nome',
        value: 'Il tuo nome',
      },
      input_surname: {
        label: 'Cognome',
        value: 'Il tuo cognome',
      },
      input_company: {
        label: 'Ragione sociale',
        value: "L'azienda per cui lavori",
      },
      input_email: {
        label: 'Email',
        value: 'La tua email',
      },
      button: 'Richiedi password',

      error_user_not_found: "Impossibile trovare l'utente",
      error_email_format: 'Formato email non corretto',
      error_form_not_complete: 'Riempi tutti i campi',
    },
    contact_us: {
      title: 'Contattaci',
      success: 'Password richiesta con successo!',
      success_send_mail: 'Richiesta di assistenza inviata con successo!',
      input_name: {
        label: 'Nome',
        value: 'Il tuo nome',
      },
      input_surname: {
        label: 'Cognome',
        value: 'Il tuo cognome',
      },
      input_company: {
        label: 'Ragione sociale',
        value: "L'azienda per cui lavori",
      },
      input_email: {
        label: 'Email',
        value: 'La tua email',
      },
      input_type_support: {
        label: 'Tipo di assistenza richiesta',
        value: "L'oggetto dell'email",
      },
      subject_options: {
        forgot_password: 'Richiedi nuova password',
        new_user: 'Registrazione nuova utenza',
        other: 'Altro',
      },
      input_message: {
        label: 'Messaggio',
        value: 'Il tuo messaggio',
      },
      button: 'Invia',

      error_fill_form: 'Riempi tutti i campi',
      error_email_format: 'Formato email non corretto',
      error_user_not_found: "Impossibile trovare l'utente tra gli utenti registrati",
      error_support_mail: "Si è verificato un errore! Impossibile inviare l'email",
      error_no_subject_or_msg: 'Nessun oggetto o messaggio inserito',
    },
    erp_user: {
      title: 'Benvenuto',
      select_erp_user: {
        label: 'Utenze ERP',
        value: 'Codice',
      },
      button: 'Entra',

      error_no_selection: "Scegli un'utenza ERP per procedere",
    },
  },

  sidebar: {
    title: 'Ciao',
    menu: {
      home: 'Home',
      dashboard: 'Dashboard',
      availability: 'Disponibilità web',
      cart: 'Carrello',
      order_history: 'Storico ordini',
      documentary: 'Documentale',
      logistic_documents: 'Documenti logistici',
      logout: 'Logout',
      admin: 'Funzionalità admin',
    },
  },

  dashboard_page: {
    title: 'La tua dashboard',
    subtitle: "Qui puoi visualizzare l'andamento dei tuoi ordini",
    select_filter_by: {
      placeholder: 'Filtra per',
      option_amount: 'Importo',
      option_quantity: 'Quantità',
    },
    select_filter_by_code: {
      placeholder: 'Filtra per codice',
    },
    select_filter_by_purchase: {
      placeholder: 'Filtra per articolo',
    },
  },

  orderHistoryPage: {
    page_title: 'Storico ordini',
    error_retrieve: 'Si è verificato un errore! Impossibile recuperare archivio ordini',
    select: {
      search: 'Cerca',
      order_by: {
        title: 'Ordina per',
        asc: 'Data crescente',
        desc: 'Data decrescente',
      },
    },
    header_columns: {
      order: 'Ordine',
      delivery_date: 'Data ordine',
      status: 'Stato',
    },
    orderStatus: {
      inserito: 'Inserito',
      spedito_parziale: 'Spedito parziale',
      approvato: 'Approvato',
      spedito: 'Spedito',
      in_preparazione: 'In lavorazione',
    },
    deliveryDateStatus: {
      estimated_date: 'Data stimata',
      not_available: 'non disponibile',
    },
    drawer: {
      title: 'Ordine n.',
      product_column: 'Prodotto',
      delivery_date_column: 'Data di consegna',
      meters_column: 'Metri',
      total_meters: 'Metri totali',
      express_shipment: 'Spedizione con corriere espresso',
      standard_shipment: 'Spedizione standard',
      externalDocumentNo: 'Nr. documento esterno',
    },
  },

  availabilityPage: {
    main: {
      title_1: 'Le tue ricerche recenti',
      input_placeholder: 'Cerca articoli',
      left_text_button: 'cerca',
      title_2: 'Risultati per: ',
      delete_message: 'Vuoi eliminare questa ricerca?',

      error_delete_recent_search: 'Si è verificato un errore! Impossibile eliminare riga di ricerca',
      error_item_availability: "Si è verificato un errore! Impossibile recuperare la disponibilità dell'articolo",
    },
    drawer: {
      title: 'Tessuto ',
      title_1: 'Scarica la scheda commerciale',
      title_2: 'Matricole disponibili',
      label_short_lots: 'Matricole con metrature limitate',
      button_2: 'Visualizza le matricole disponibili',
      button_2_1: 'Nessuna Matricola Disponibile',

      subtitle_2: '',
      title_3: 'In arrivo',
      button_3: 'Visualizza la disponibilità futura',
      button_3_1: 'Nessuna disponibilità futura trovata',
      left_button: 'Metratura',
      right_button: 'Disponibilità',
      //button: 'Aggiungi al carrello ',
      button: 'Prosegui',
      label_batches: 'Lotti selezionati',
      label_meters: 'Metri',
      label_discount: '!! Sconto 30% !!',

      error_msg: 'Nessun lotto selezionato o metro inserito',

      //modal_content: "La disponibiltà non copre l'ordine desiderato.",
    },
    sub_drawer_1: {
      title: 'Totali matricole disponibili: ',
      subtitle_1: 'N. partita',
      subtitle_2: 'N. matricola',
      subtitle_3: 'Metri',
      column_support: 'Tutta altezza',
      button: 'Aggiungi <n> metri',
      qty_selectable: 'Quantità selezionabile: ',
      qty_selected: 'Quantità selezionata: ',

      modal_title: 'Attenzione',
      modal_content: "Quantità massima selezionabile superata. L'ultimo lotto scelto verrà deselezionato.",
    },
    sub_drawer_2: {
      title: 'Disponibilità in arrivo',
      subtitle_1: 'Data',
      subtitle_2: 'Disponibilità totale',
    },
    sub_drawer_3: {
      title_1: 'Lotti selezionati',
      title_short_lots: 'Lotti con metrature limitate',
      title_2: 'Metri sfusi',
      label_prospect_dates: 'Data',
      label_delivery: 'Data di consegna',
      label_status: 'Stato',
      status_immediateAvailability: 'disponibilità immediata',
      status_futureAvailability: 'disponibilità futura',
      status_remainings: 'non disponibili',
      label_total: 'Totale',
      //tooltip_delivery_info: 'La data di consegna è soggetta a variazione dovuta ai tempi di rientro della merce in magazzino.',
      tooltip_delivery_info:
        "La data di consegna può essere soggetta a variazione dovuta ai tempi di rientro dell'articolo in magazzino",
      button_add_to_cart: 'Aggiungi al carrello ',

      support_disclaimer: 'lotto disponibile in un supporto diverso da quello preferito',
      error_cart: 'Si è verificato un errore! Impossibile creare un carrello',
      info_add_to_cart: 'Articoli aggiunti correttamente al carrello',
      error_add_to_cart: 'Si è verificato un errore! Articoli non aggiunti al carrello',
      error_nothing_to_add: 'Nessun metro o lotto da inserire nel carrello',
      modal_content:
        "Alcuni dei lotti selezionati sono disponibili in un supporto diverso da quello preferito. Si desidera procedere ugualmente all'aggiunta al carrello?",
      modal_cancel_button: 'Annulla',
    },
  },

  cartPage: {
    title: 'Il tuo carrello',
    subtitle1: 'Prodotto',
    subtitle2: 'Matricole/Q.tà',
    subtitle3: 'Data di consegna',
    cart_row: ' matricole | Tot. ',
    cart_delete: 'Vuoi eliminare il prodotto?',
    cart_delete_response: 'Prodotto eliminato',
    cart_empty: 'Carrello vuoto',
    info_confirm: 'Grazie per il suo ordine!',
    error_confirm: "Si è verificato un errore! Impossibile inviare l'ordine",
    error_delete_row: "Si è verificato un errore! Impossibile eliminare riga d'ordine",
    error_email_format: 'Formato mail non corretto',

    modal_title: 'Grazie per il suo acquisto!',
    modal_content: "L'ordine è stato evaso correttamente",

    drawer: {
      title1: 'Metri totali',
      title2: 'Numero matricole',
      title3: 'Metri con matricole',
      title4: 'Metri residui',
      button: 'Conferma',
      express_shipment: 'Spedizione con corriere espresso',
      external_document: 'Nr. documento esterno',
    },
  },

  documentalPage: {
    title: 'Documentale',
    category: {
      category1: 'N. Documento',
      category2: 'Data documento',
      category3: 'Download',
    },
    input_docType: {
      order: 'Ordini',
      ddt: 'DDT',
      packinglist: 'Lista dei colli',
      invoice: 'Fatture',
    },
  },

  customersPage: {
    searchbar_placeholder: 'Cerca',
    checkbox_active: 'Attivi',
    checkbox_inactive: 'Disattivi',
    button_add_user: 'Aggiungi utente',

    option_expired_password: 'SCADENZA PASSWORD',
    option_company: 'RAGIONE SOCIALE',

    button_extended: 'Esteso',
    button_limited: 'Limitato',
    button_expiration_days: '30+gg',
    button_days: 'gg',

    drawer_password: {
      title: 'La password scadrà tra:',
      subtitle_days: 'giorni',
      button_generate: 'Genera nuova password',
      button_confirm: 'Conferma',

      error_password: 'Impossibile generare nuova password',
      info_modify_password: 'Password utente modificata correttamente',
      error_modify_password: 'Impossibile modificare la password',
    },

    drawer_language: {
      title1: 'Lingua in uso: ',
      italian: 'ITALIANO',
      english: 'INGLÉS',
      spanish: 'ESPAÑOL',
      title2: 'Assegna una lingua: ',
      button_confirm: 'Conferma',

      info_changed_language: 'Lingua modificata correttamente in: ',
      error_add_user: 'Si è verificato un errore! Impossibile inserire il nuovo utente',
    },

    drawer_info: {
      title1: 'Informazioni sul profilo con ID: ',
      label_name: 'Nome',
      label_surname: 'Cognome',
      label_email: 'Email',
      label_business_name: 'Ragione sociale',
      title2: 'Tipologia di profilo',
      label_erp_users: 'Utenze ERP',
      button_confirm: 'Conferma',
      placeholder_erp_users: 'Cerca qui la tua utenza ERP',

      info_modify_user: 'Utente modificato correttamente',
      error_modify_user: 'Impossibile salvare le modifiche sulle informazioni utente',
      error_erp_users: 'Impossibile scaricare gli utenti erp',

      modal_title: 'Attenzione! ',
      modal_context: "Stai modificando il ruolo dell'utenza",
      modal_okText: 'Ho capito',
      modal_okType: 'danger',
    },

    customer_row: {
      info_active: "Stato di Active dell'utente modificato correttamente",
      error_active: 'Impossibile modificate stato di Active',
      info_limited: 'Visibilità account modificata correttamente',
      error_limited: 'Impossibile modificare visibilità profilo',
    },
  },

  domain: {
    select_order_by: {
      date: {
        asc: 'Data crescente',
        desc: 'Data decrescente',
      },
    },
  },

  homePage: {
    greeting: 'Benvenuto su',
  },

  misc: {
    erps_selected: 'Utenze selezionate',
  },
  error: {
    user_not_valid: 'Utente non valido',
    password_not_valid: 'Password non valida',
    user_or_password_wrong: 'Utente o password errati',
  },
  warning: {
    warning_cart_two_minutes: 'Il carrello verrà svuotato tra meno di 2 minuti!',
    warning_cart_ten_seconds: 'Il carrello verrà svuotato tra 10 secondi!',
  },
  messages: {},
  placeholder: {},
  confirm: {},
  labels: {},
}
