import styled from 'styled-components'
import { Button as AntButton } from 'antd'
import { useEffect, useState } from 'react'
import { Button, Input, Spacer } from 'components'
import Auth from 'api/Auth'
import { CaretLeftOutlined } from '@ant-design/icons'
import { ReactComponent as LoadingIcons } from 'assets/loading.svg'
import i18n from 'translations/i18n'
import { validNewPassword } from 'utils/regex'
import { showError, showInfo } from 'utils'
import ApiError from 'types'

export default function EditPasswordCard({
  onClick,
  onPasswordExpiredSuccess,
  userCode,
}: {
  onClick: (value: boolean) => void
  onPasswordExpiredSuccess: (value: string) => void
  userCode: string
}) {
  const [username, setUsername] = useState<string>('')
  const [oldPassword, setOldPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setUsername(userCode)
  }, [userCode])

  const sendChangedPassword = async () => {
    // Check required fields
    if (username === '' || oldPassword === '' || newPassword === '' || confirmNewPassword === '') {
      return showError(i18n.t('login_page.edit_password.empty_input_fields'))
    }

    // Check password length
    if (
      newPassword.length < 12 ||
      newPassword.length > 25
    ) {
      return showError(i18n.t('login_page.edit_password.password_length_error'))
    }

    // Check new password validity
    if (!validNewPassword.test(newPassword)) {
      return showError(i18n.t('login_page.edit_password.new_password_error'))
    }

    // Check confirm password validity
    if (confirmNewPassword !== newPassword) {
      return showError(i18n.t('login_page.edit_password.new_password_diff_confirm_password'))
    }

    try {
      setLoading(true)
      await Auth.editPassword(username, oldPassword, newPassword)
      setLoading(false)

      onPasswordExpiredSuccess(username)

      showInfo(i18n.t('login_page.edit_password.correct_credentials'))
    } catch (error) {
      setLoading(false)
      const knownError = error as ApiError

      switch (knownError.code) {
        case 'WRONG_CREDENTIALS':
          showError(i18n.t('login_page.edit_password.wrong_credentials'))
          break
        case 'USER_NOT_FOUND':
          showError(i18n.t('login_page.edit_password.user_not_found'))
          break
        case 'TOO_SHORT':
          showError(i18n.t('login_page.edit_password.password_too_short'))
          break
        case 'TOO_LONG':
          showError(i18n.t('login_page.edit_password.password_too_long'))
          break
        case 'MISSING_NUMBERS':
          showError(i18n.t('login_page.edit_password.missing_numbers'))
          break
        case 'MISSING_SPECIAL_CHAR':
          showError(i18n.t('login_page.edit_password.missing_special_char'))
          break
        case 'MISSING_UPPERCASE_CHAR':
          showError(i18n.t('login_page.edit_password.missing_uppercase_char'))
          break
        case 'MISSING_LOWERCASE_CHAR':
          showError(i18n.t('login_page.edit_password.missing_lowercase_char'))
          break
        case 'SPACES_NOT_ALLOWED':
          showError(i18n.t('login_page.edit_password.spaces_not_allowed'))
          break
        case 'NEW_PASSWORD_EQUAL_TO_OLD':
          showError(i18n.t('login_page.edit_password.new_password_equal_to_old'))
          break
        default:
          showError(i18n.t('login_page.edit_password.default_error'))
      }

      return
    }
  }

  return (
    <>
      <CardTitle>{i18n.t('login_page.edit_password.title')}</CardTitle>
      <BackButton>
        <AntButton onClick={() => onClick(true)} shape="circle" icon={<CaretLeftOutlined />} />
      </BackButton>
      <Spacer />
      {loading && <LoadingIcons />}
      {!loading && (
        <FormContainer>
          <Spacer />
          <Input
            type="text"
            title={i18n.t('login_page.edit_password.input_username.label') + '*'}
            hidetext
            placeholder={i18n.t('login_page.edit_password.input_username.value')}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Spacer />
          <Input
            type="password"
            title={i18n.t('login_page.edit_password.input_old_password.label') + '*'}
            hidetext
            placeholder={i18n.t('login_page.edit_password.input_old_password.value')}
            defaultValue={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <Spacer />
          <Input
            type="password"
            title={i18n.t('login_page.edit_password.input_new_password.label') + '*'}
            hidetext
            placeholder={i18n.t('login_page.edit_password.input_new_password.value')}
            defaultValue={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Spacer />
          <Input
            type="password"
            title={i18n.t('login_page.edit_password.input_confirm_new_password.label') + '*'}
            hidetext
            placeholder={i18n.t('login_page.edit_password.input_confirm_new_password.value')}
            defaultValue={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
          <Spacer />
        </FormContainer>
      )}
      <Spacer />
      <TextRequiredFields>*{i18n.t('login_page.edit_password.required_fields')}</TextRequiredFields>
      <Spacer />
      <SendButton onClick={sendChangedPassword} text={i18n.t('login_page.edit_password.button')} />
    </>
  )
}

const CardTitle = styled.h1`
  font-family: cormorant-garamond, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 4vw;
  color: #28293d;
  display: flex;
  align-items: center;
  text-align: center;
  place-content: center;
  margin-top: 1vw;
  margin-bottom: 1vw;
`
const BackButton = styled.div`
  align-self: flex-start;
  margin-left: 7%;
  margin-top: -7%;
`
const FormContainer = styled.div`
  width: 28vw;
  margin-left: 2vw;
`

const SendButton = styled(Button)`
  font-size: 1.3em !important;
  padding: 12 - 180px !important;
`
const TextRequiredFields = styled.p`
  font-size: 1vw;
`
